const routes = [
    {
        path: '',
        redirect: '/index'
    },
    {
        name: 'index',
        path: '/index',
        component: () => import('@/pages/index.vue')
    },
    {
        name: 'ugly_face',
        path: '/ugly_face',
        component: () => import('@/pages/ugly_face.vue')
    },
    {
        name: 'test',
        path: '/test',
        component: () => import('@/pages/test.vue')
    },
    {
        name: 'test_iframe',
        path: '/test_iframe',
        component: () => import('@/pages/test_iframe.vue')
    }
];
export default routes