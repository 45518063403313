<template>

  <div class="base-app-class">
    <el-menu
      :default-openeds="openeds"
      style="border-right: 0px;"
      class="el-menu-demo"
      :default-active="$route.path"
      mode="vertical">
      <el-menu-item index="/index" @click="gotoIndex()"><el-icon><HomeFilled /></el-icon>存图网</el-menu-item>
      <el-menu-item index="0" @click="showHistory()"><el-icon><Document /></el-icon>历史记录</el-menu-item>
      <el-menu-item index="1" @click="wechatMini()"><el-icon><Iphone /></el-icon>微信小程序</el-menu-item>
      <el-menu-item index="/ugly_face" @click="gotoUglyHead()"><el-icon><Avatar /></el-icon>丑丑头像</el-menu-item>      
      <!-- <el-menu-item></el-menu-item> -->
      
      <!-- <el-menu-item index="5" @click="gotoShop()"><el-icon><ShoppingBag /></el-icon>红包封面</el-menu-item> -->
      <el-menu-item index="3" @click="gotoTalking()"><el-icon><ChatDotSquare /></el-icon>交流论坛</el-menu-item>
      <el-menu-item index="4" @click="gotoChatWeb()"><el-icon><Cpu /></el-icon>AI&nbsp;助手</el-menu-item>

      <!-- <el-menu-item></el-menu-item> -->

      <el-menu-item index="2" @click="zanshang()"><el-icon><Coffee /></el-icon>请喝咖啡</el-menu-item>

      <el-sub-menu index="7">
        <template #title>
          <span><el-icon><Setting /></el-icon>设置</span>
        </template>
        <el-menu-item index="7-1" @click="agreement()"><el-icon><WarningFilled /></el-icon>隐私协议</el-menu-item>
        <el-menu-item index="7-2" @click="clearHistory()"><el-icon><DeleteFilled /></el-icon>清空历史</el-menu-item>
        <!-- <el-menu-item index="7-2" @click="exit()"><el-icon><Switch /></el-icon>切换账号</el-menu-item> -->
      </el-sub-menu>

      <el-sub-menu index="6">
        <template #title>
          <span><el-icon><Link /></el-icon>友链</span>
        </template>
        <!-- <el-menu-item index="6-1" @click="gotoPibazi()"><el-icon><Pointer /></el-icon>生辰八字</el-menu-item> -->
        <!-- <el-menu-item index="6-2" @click="gotoSanshu()"><el-icon><TakeawayBox /></el-icon>三叔工具箱</el-menu-item> -->
        <el-menu-item index="6-3" @click="gotoYoulian()"><el-icon><Message /></el-icon>友链申请</el-menu-item>
        <el-menu-item index="6-4" @click="gotoYoulian()"><el-icon><Link /></el-icon>API申请</el-menu-item>
      </el-sub-menu>

      <!-- <el-menu-item></el-menu-item> -->
      

    </el-menu>

    <router-view class="router-class"></router-view>

  </div>

  <div class='bottom-base-div' >
    <div class="bottom-text-div">
      © 2018-2024 wenyiwen.wang 版权所有
    </div>
    <div class="bottom-text-div" @click="gotoBeian()">
      ICP证：豫ICP备17040719号
    </div>
  </div>

  <el-dialog v-model="dialogTableVisible" title="最近获取成功的20条记录">
    <el-table :data="historyData">
      <el-table-column property="date" label="时间" width="150px" align="center" header-align="center" />
      <el-table-column property="data" label="链接" header-align="center" />
      <el-table-column label="操作" width="150px" align="center" header-align="center" >
        <template #default='scope'>
          <el-button round type="primary" size="small" @click="copyData(scope.row.data, $event)">复制链接</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

  <el-dialog v-model="dialogAgreementVisible" title="隐私协议">
    <div class="agreement-div" v-for="item in agreementData" :key="item">
      <h3>{{item}}</h3>
    </div>
    <!-- <el-table :data="historyData">
      <el-table-column property="date" label="时间" width="150px" align="center" header-align="center" />
      <el-table-column property="data" label="链接" header-align="center" />
      <el-table-column label="操作" width="150px" align="center" header-align="center" >
        <template #default='scope'>
          <el-button link type="primary" size="small" @click="copyData(scope.row.data, $event)">复制链接</el-button>
        </template>
      </el-table-column>
    </el-table> -->
  </el-dialog>
  
</template>

<script>

import { ElMessageBox, ElMessage } from 'element-plus'
import clipboard from '@/util/clipboard'
import { ref } from 'vue'
import { h } from 'vue'

export default {
  name: 'App',
  components: {

  },
  data () {
    return {

      dialogTableVisible: ref(false),

      dialogAgreementVisible: ref(false),

      historyData: [],

      agreementData: [],

      // openeds: ['6','7']
    }
  },

  created() {
    this.createAgreementData();
  },

  methods: {

    agreement() {
      this.dialogAgreementVisible = true;
    },

    gotoBeian() {
      window.open("http://beian.miit.gov.cn/", "_black")
    },

    createAgreementData() {
        var baseTitle = "存图网";

      	this.recordArr = new Array();
			  var info1 = "尊敬的用户，欢迎使用由" + baseTitle + "提供的服务。在使用前请您阅读如下服务协议，使用本应用即表示您同意接受本协议，请仔细阅读。如有任何问题，可联系客服咨询。";
			  this.recordArr.push(info1);
			  
			  var info2 = "一、服务条款的确认和接受。";
			  this.recordArr.push(info2);
			  
			  var info3 = "通过访问或使用本应用，表示用户同意接受本协议的所有条件和条款。";
			  this.recordArr.push(info3);
			  
			  var info4 = "二、隐私条款";
			  this.recordArr.push(info4);
			  
			  var info5 = "用户信息公开情况说明：尊重用户个人隐私是" + baseTitle + "的一项基本原则。所以" + baseTitle + "不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本应用中的内容。";
			  this.recordArr.push(info5);
			  
			  var info6 = "三、本应用涉及到的具体用户隐私权限信息如下";
			  this.recordArr.push(info6);
			  
			  var info7 = "1、本应用获取到的用户信息字段为【用户头像】【用户昵称】，并用于页面进行展示。";
			  this.recordArr.push(info7);
			  
			  var info8 = "2、本应用不包含任何第三方插件，并且不会通过第三方插件去获取您的任何信息。";
			  this.recordArr.push(info8);
			  
			  var info9 = "3、用户权益。如果您想编辑、删除个人的隐私信息，可以通过邮件或其他联系方式告知我们，我们将在24小时内修正或删除。";
			  this.recordArr.push(info9);
			  
			  var info10 = "4、本应用使用您的个人信息仅限于上列条款中的在页面展示，并且不会对外公开展示。";
			  this.recordArr.push(info10);
			  
			  var info11 = "5、获取剪切板内容。本应用获取剪切板权限是为了提高您的使用体验，并且用于对后续的业务服务。";
			  this.recordArr.push(info11);
			  
			  var info12 = "6、保存图片到系统。本应用使用此权限是为了将获取到的图片素材保存到您的相册中。";
			  this.recordArr.push(info12);
			  
			  var info13 = "7、保存视频到系统。本应用使用此权限是为了将获取到的视频素材保存到您的相册中。";
			  this.recordArr.push(info13);
			  
			  var info14 = "8、信息对外提供。本应用不会通过任何方式将您的信息对外提供，请放心使用。";
			  this.recordArr.push(info14);

        var info15 = "9、图片、视频、文案版权归复制链接所在的平台及作者所有，本站不存储任何图片、视频、文案数据。";
			  this.recordArr.push(info15);
			  
			  var info16 = "联系客服：860993707@qq.com";
			  this.recordArr.push(info16);

        this.agreementData = this.recordArr;
    },

    gotoUglyHead() {
      this.$router.push({
        path: '/ugly_face'
      })
    },

    gotoIndex() {
      this.$router.push({
        path: '/index'
      })
    },

    gotoSanshu() {
      window.open("https://sanshu.cn")
    },

    gotoTalking() {
      window.open("https://support.qq.com/product/193477")
    },

    gotoChatWeb() {
      window.open("https://chat.wenyiwen.wang")
    },

    gotoYoulian() {
      window.open("mailto:860993707@qq.com")
    },

    gotoPibazi() {
      window.open("/pibazi/index.html"); 
    },

    aiRobot() {
      // var imgUrl = "/cuntuweb/img/aigroup.jpg";
      var imgUrl = "/img/aigroup.jpg";
      var tempIndex = Math.ceil(Math.random()*1000);
      var tempMessage = h('img', { src: imgUrl, fit: 'cover', class: 'el-message-box-image-class', index: tempIndex }, null);
      this.showImg("微信号：ai-chat-666", tempMessage);
    },

    zanshang() {
      // var imgUrl = "/cuntuweb/img/zanshang.png";
      var imgUrl = "/img/zanshang.png";
      var tempIndex = Math.ceil(Math.random()*1000);
      var tempMessage = h('img', { src: imgUrl, fit: 'cover', class: 'el-message-box-image-class', index: tempIndex }, null);
      this.showImg("请站长喝杯咖啡吧~", tempMessage);
    },

    wechatMini() {
      // var imgUrl = "/cuntuweb/img/mini.jpg";
      var imgUrl = "/img/mini.jpg";
      var tempIndex = Math.ceil(Math.random()*1000);
      var tempMessage = h('img', { src: imgUrl, fit: 'cover', class: 'el-message-box-image-class', index: tempIndex }, null);
      this.showImg("微信扫码使用小程序", tempMessage);
    },

    showImg(titleInfo, messageInfo) {   
      var that = this;
      ElMessageBox({
        title: titleInfo,
        message: messageInfo,
        showConfirmButton: false,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: '关闭',
        showClose: false,
        center: true,
        closeOnClickModal: false,
        roundButton: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = 'Loading...'
            setTimeout(() => {
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 3000)
          } else {  
            done()
          }
        },
      }).then((action) => {
        // ElMessage({
        //   type: 'info',
        //   message: `action: ${action}`,
        // })
      }).catch((action)=>{

      });
    },

    clearHistory() {
      localStorage.setItem('cuntu-history', null);
      ElMessage({
        message: '清除完成',
        type: 'success',
      })
    },

    showHistory() {
      var baseData = JSON.parse(localStorage.getItem("cuntu-history"));
      if(baseData != null && baseData != undefined && baseData.length > 0) {
        this.historyData = baseData.reverse();
      }else{
        this.historyData = []
      }
      this.dialogTableVisible = true;
    },

    copyData(data, event) {
      clipboard(data, event)
    },

    gotoShop() {
      // var imgUrl = "/cuntuweb/img/weidian.png";
      var imgUrl = "/img/mini.jpg";
      var tempIndex = Math.ceil(Math.random()*1000);
      var tempMessage = h('img', { src: imgUrl, fit: 'cover', class: 'el-message-box-image-class', index: tempIndex }, null);
      this.showImg("微信扫码浏览", tempMessage);
    },

    exit() {
      ElMessageBox.alert('确认退出？', '提示', {
        confirmButtonText: '确认',
        showCancelButton: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        showClose: false,
        center: true,
        closeOnClickModal: false,
        roundButton: true,
        callback: (action) => {
          // ElMessage({
          //   type: 'info',
          //   message: `action: ${action}`,
          // })
          if(action == 'confirm') {
            localStorage.setItem('cuntu-user', null);
            window.location.reload()
          }
        },
      });
    }
  }
}
</script>

<style>

.agreement-div {
  text-align: left;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* .black-menu-itme {
  flex: auto;
} */

.el-message-box-image-class {
  width: 160px;
  height: 160px;
  margin-top: 20px;
  will-change: transform;
	overflow:hidden;
  border-radius: 10px 10px 10px 10px;
  /* box-shadow: 8px 8px 6px #DDDDDD; */
}

.el-menu-demo {

}

.router-class {
  flex: 1;
}

.base-app-class {
  display: flex;
  flex-direction: row;
}


.bottom-text-div {
  color: #a6a6a6;
  font-size: 10px;
}

.bottom-base-div {
  text-align: center;
  width: 100%; 
  position: fixed; 
  bottom: 0px;
}

h3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
